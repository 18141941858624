<template>
  <section class="">
    <div class="row mb-3">
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <h3 class="bis d-flex align-items-center">
          <div
            class="icon-title d-none d-md-flex align-items-center justify-content-center pointer"
          >
            <icon width="30" height="30" name="home"></icon>
          </div>
          <div>
            <div class="d-md-flex">
              <div>
                Votre espace pour le concours
              </div>
              <m-form-select
                :name="$Utils.randomstring('items_years')"
                :items="$store.state.items_years"
                style="width:110px"
                class="ms-2 input-year"
                :clearable="false"
                v-model="year"
              ></m-form-select>
            </div>
            <div class="fs-6 fw-light mt-1 mt-md-0">
              Cliquez sur l'année pour visualiser les années précédentes
            </div>
          </div>
        </h3>
        <button @click="showWinContact" class="btn btn-dark btn-sm ms-2">
          Contact
        </button>
      </div>
    </div>
    <div
      class="alert alert-danger"
      v-if="
        $store.state.userparticipation.yearpreferences.yp_year ==
          $store.state.currentyear && $store.state.yearObj.period != 1
      "
      v-html="$store.state.yearObj.periodText"
    ></div>
    <div class="d-md-flex justify-content-between mb-3">
      <div class="frame w-100 me-md-2 ">
        <h4 class="">Mon compte</h4>
        <div class="">{{ row_pa.pa_society }}</div>
        <div class="">
          {{ $options.filters.formatContactNameSimple(row_pa, "pa_", true) }}
          <span v-if="row_pa.pa_fonction"> - {{ row_pa.pa_fonction }}</span>
        </div>
        <div v-html="$options.filters.formatAddress(row_pa, 'pa_')"></div>
        <div>
          <div v-if="row_pa.pa_phone_fix">
            Tél fixe : {{ row_pa.pa_phone_fix }}
          </div>

          <div v-if="row_pa.pa_phone_mobile">
            | Tél mobile : {{ row_pa.pa_phone_mobile }}
          </div>
        </div>
        <div v-html="$options.filters.formatEmail(row_pa.login.lo_login)"></div>
        <div>
          <a :href="row_pa.pa_web" target="bank">{{ row_pa.pa_web }}</a>
        </div>
        <!--         <h5 class="pointer text-decoration-underline" @click="editCandidat">
          Voir plus d'infos
        </h5> -->
        <button
          @click="editCandidat"
          class="btn btn-primary mt-3"
          v-if="
            $dayjs().isBetween(
              $store.state.userparticipation.yearpreferences
                .yp_start_inscription_date,
              $store.state.userparticipation.yearpreferences
                .yp_end_inscription_date_candidate,
              'day',
              '[]'
            ) ||
              $dayjs().isBetween(
                $store.state.userparticipation.yearpreferences
                  .yp_start_inscription_date,
                $store.state.userparticipation.yearpreferences
                  .yp_end_inscription_date_jure,
                'day',
                '[]'
              )
          "
        >
          Modifier
        </button>
      </div>
      <div
        class="frame w-100 mx-md-2"
        :class="row_pa.pa_candidat ? '' : 'color-gray'"
      >
        <div class="d-flex">
          <h4 class="">
            {{
              row_pa.pa_candidat
                ? "Je suis candidat"
                : "Je souhaite être candidat"
            }}
          </h4>
          <div
            v-if="row_pa.pa_jure && row_pa.pa_jure_valide"
            class="bg-success py-1 px-2 color-white ms-3"
          >
            Candidature validée
          </div>
        </div>

        <h5>Adresse de facturation</h5>
        <div class="">{{ row_pa.pa_society_fac }}</div>
        <div
          v-html="
            $options.filters.formatAddress(row_pa, 'pa_', false, false, '_fac')
          "
        ></div>
        <button
          v-if="
            $dayjs().isBetween(
              $store.state.userparticipation.yearpreferences
                .yp_start_inscription_date,
              $store.state.userparticipation.yearpreferences
                .yp_end_inscription_date_candidate,
              'day',
              '[]'
            )
          "
          @click="editCandidat"
          class="btn btn-primary mt-3"
        >
          Modifier
        </button>
      </div>
      <div
        class="frame w-100 ms-md-2"
        :class="row_pa.pa_jure ? '' : 'color-gray'"
      >
        <div class="d-flex">
          <h4 class="">
            {{
              row_pa.pa_jure && row_pa.pa_jure_valide
                ? "Je suis juré"
                : "Je souhaite être juré"
            }}
          </h4>
          <div>
            <div
              v-if="row_pa.pa_jure && row_pa.pa_jure_valide"
              class="bg-success py-1 px-2 color-white ms-3"
            >
              Candidature validée
            </div>
          </div>
          <div>
            <div
              v-if="row_pa.pa_jure && !row_pa.pa_jure_valide"
              class="bg-danger py-1 px-2 color-white ms-3"
            >
              En attente de validation
            </div>
          </div>
        </div>

        <div class="">
          Première participation :
          {{ row_pa.pa_firstparticipation | formatYesNoNothing }}
        </div>
        <div class="">
          Catégorie :
          {{
            row_pa.pa_typejure === 9
              ? row_pa.pa_typejure_other
              : $options.filters.formatvalue(
                  row_pa.pa_typejure,
                  "items_typejure",
                  "uppercase",
                  "singular"
                )
          }}
        </div>
        <div class="">
          Cours de dégustation :
          {{ row_pa.pa_coursdegustation | formatYesNoNothing }}
        </div>
        <div class="">
          Lien :
          <span v-if="row_pa.pa_liensexistant_more">{{
            row_pa.pa_liensexistant_more
          }}</span>
          <span v-else>{{
            row_pa.pa_liensexistant | formatYesNoNothing("Oui", "Aucun lien")
          }}</span>
        </div>
        <!-- <div class="">Centre : ?</div> -->
        <div class="d-flex align-items-end">
          <button
            v-if="
              $dayjs().isBetween(
                $store.state.userparticipation.yearpreferences
                  .yp_start_inscription_date,
                $store.state.userparticipation.yearpreferences
                  .yp_end_inscription_date_jure,
                'day',
                '[]'
              )
            "
            @click="editCandidat"
            class="btn btn-primary mt-3 "
          >
            Modifier
          </button>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-end mt-3"
      v-if="
        row_pa.pa_jure &&
          !row_pa.pa_candidat &&
          $store.state.preferences.canOrderMacaron
      "
    >
      <button
        @click="newOrder"
        class="btn btn-primary  mx-1 my-1 btn-sizing"
        v-if="$store.state.preferences.canOrderMacaron"
      >
        Commander macarons
      </button>
    </div>
    <div v-if="row_pa.pa_candidat">
      <div class="mt-4">
        <div
          v-if="
            $dayjs().isBetween(
              $store.state.userparticipation.yearpreferences
                .yp_start_inscription_date,
              $store.state.userparticipation.yearpreferences
                .yp_end_inscription_date_candidate,
              'day',
              '[]'
            )
          "
          class="frame d-flex flex-column"
          :class="
            row_pa && row_pa.winesCreate.length < 1 ? 'frame-wines-height' : ''
          "
        >
          <h4 class="mb-3">
            {{
              !row_pa.winesCreate.length
                ? "Ajouter un vin"
                : "" +
                  $options.filters.plural(
                    row_pa.winesCreate.length,
                    "Mon vin",
                    "Mes %n vins"
                  ) +
                  " en cours d'inscription"
            }}
          </h4>
          <div
            class="d-flex justify-content-center align-items-center flex-grow-1"
            v-if="!row_pa.winesCreate.length"
          >
            <div @click="addWine" class="d-flex align-items-center pointer">
              <div>
                <img src="/images/icons/plus-bleu.png" alt="icon plus" />
              </div>
              <div class="ms-3" style="width:170px;">
                Cliquez sur le bouton pour ajouter
                <span v-if="row_pa.winesValide.length === 0"
                  >votre premier vin</span
                >
                <span v-else>d'autres vins</span> participant au concours
              </div>
            </div>
          </div>
          <m-list-simple
            v-else
            tableClass="table-hover table-striped"
            style="overflow: visible;"
            ref="wineslist"
            :items="row_pa.winesCreate"
            item-value="wi_id"
            :total="row_pa.winesCreate.length"
          >
            <template v-slot:ths="{}"> </template>
            <template v-slot:tds="{ item }">
              <td class="d-flex align-items-center">
                <div class="p-2 d-none d-md-block">
                  <img
                    v-if="!item.wi_revendication || !item.wi_analyse"
                    class="img-fluid img-macaron"
                    src="/images/stop.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="img-fluid img-macaron"
                    src="/images/ok.png"
                    alt=""
                  />
                </div>
                <div class="ms-md-3">
                  <div class="fw-bold">
                    {{ item | formatWineName }}
                    <!-- <span v-if="item.wi_name2"> / {{ item.wi_name2 }}</span> -->
                  </div>
                  <div>
                    <span>{{ item.denomination.de_name }} - </span>
                    <span>{{ item.wi_couleur | formatWineColor }} - </span>
                    <span
                      >{{ item.wi_millesime | formatWineMillesime }} |
                    </span>
                    <span>
                      Nombre de vins déclarés :
                      <span
                        v-for="(contenant, index) in item.contenants"
                        :key="index"
                      >
                        {{ contenant.nombre }} x
                        {{ contenant.contenant / 100 }} cl
                        <span v-if="index < item.contenants.length - 1"
                          >,</span
                        ></span
                      ></span
                    >
                  </div>
                  <div>
                    <span>Fichiers : </span>
                    <span class="color-red" v-if="item.wi_revendication">
                      <span @click="downloadFile(1, item)" class="pointer"
                        >Déclaration de revendication
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            item.wi_revendication2 ||
                            item.wi_revendication3 ||
                            item.wi_revendication4 ||
                            item.wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication2">
                      <span @click="downloadFile(10, item)" class="pointer"
                        >Déclaration de revendication 2
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication2 &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            item.wi_revendication3 ||
                            item.wi_revendication4 ||
                            item.wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication3">
                      <span @click="downloadFile(11, item)" class="pointer"
                        >Déclaration de revendication 3
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication3 &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            item.wi_revendication4 ||
                            item.wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication4">
                      <span @click="downloadFile(12, item)" class="pointer"
                        >Déclaration de revendication 4
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication4 &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            item.wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication5">
                      <span @click="downloadFile(13, item)" class="pointer"
                        >Déclaration de revendication 5
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication5 &&
                          (item.wi_analyse || item.wi_fichetech)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_analyse">
                      <span @click="downloadFile(2, item)" class="pointer"
                        >Rapport d'analyse
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="item.wi_fichetech && item.wi_analyse"
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_fichetech">
                      <span @click="downloadFile(3, item)" class="pointer"
                        >Fiche technique</span
                      ></span
                    >
                  </div>
                  <!-- vue sur petits écrans -->
                  <div class="d-md-none">
                    <div v-if="!item.wi_revendication || !item.wi_analyse">
                      <div class="text-danger fw-bold">
                        Attention, il manque :
                      </div>
                      <div v-if="!item.wi_revendication">
                        Déclaration de revendication
                      </div>
                      <div v-if="!item.wi_analyse">Rapport d'analyse</div>
                    </div>
                    <div v-else class="text-success">
                      Fiche correctement remplie
                    </div>
                    <div class="d-flex align-items-center">
                      <button @click="editWine(item)" class="btn btn-primary">
                        Modifier
                      </button>
                      <div
                        class="pointer ms-2"
                        @click="confirmDeleteWine(item)"
                      >
                        <icon color="#950605" name="trash-alt"></icon>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <!-- vu sur grands écrans -->
              <td class="align-middle d-none d-md-table-cell">
                <div v-if="!item.wi_revendication || !item.wi_analyse">
                  <div class="text-danger fw-bold">Attention, il manque :</div>
                  <div v-if="!item.wi_revendication">
                    Déclaration de revendication
                  </div>
                  <div v-if="!item.wi_analyse">Rapport d'analyse</div>
                </div>
                <div v-else class="text-success">
                  Fiche correctement remplie
                </div>
              </td>
              <td class="align-middle d-none d-md-table-cell">
                <button @click="editWine(item)" class="btn btn-primary">
                  Modifier
                </button>
              </td>
              <td class="align-middle d-none d-md-table-cell">
                <div class="pointer" @click="confirmDeleteWine(item)">
                  <icon color="#950605" name="trash-alt"></icon>
                </div>
              </td>
            </template>
          </m-list-simple>
          <div class="d-flex justify-content-end align-items-end flex-grow-0">
            <button @click="addWine" class="btn btn-primary">
              Ajouter un vin
            </button>
            <button
              v-if="row_pa.winesCreate.length"
              @click="payer()"
              style="color:white"
              :disabled="disabledPayer"
              :class="{
                btn: true,
                'btn-success': !disabledPayer,
                'btn-secondary': disabledPayer,
                'ms-2': true
              }"
            >
              Valider mon inscription
            </button>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="frame" v-if="row_pa.pa_candidat_valide">
          <div class="d-flex justify-content-between">
            <h4 class="mb-3">
              {{ getTitleVinsIscrits() }}
            </h4>
            <div>
              <button
                class="btn btn-primary mx-1 my-1 btn-sizing"
                @click="printColisage()"
                :disabled="!canPrintColisage"
              >
                Imprimer colisage
              </button>
              <button
                @click="newOrder"
                class="btn btn-primary  mx-1 my-1 btn-sizing"
                v-if="$store.state.preferences.canOrderMacaron"
              >
                Commander macarons
              </button>
            </div>
          </div>
          <m-list-simple
            tableClass="table-hover table-striped"
            style="overflow: visible;"
            ref="wineslist"
            :items="row_pa.winesValide"
            item-value="wi_id"
            :total="row_pa.winesValide.length"
            mListSimpleTrClass="d-md-flex"
          >
            <template v-slot:ths="{}"> </template>
            <template v-slot:tds="{ item }">
              <td
                class="d-md-flex align-items-center"
                style="min-height:110px;"
              >
                <div class="p-2">
                  <div
                    style="min-width: 80px;"
                    v-if="
                      $dayjs().isSameOrAfter(
                        $store.state.userparticipation.yearpreferences
                          .yp_results_date,
                        'day'
                      )
                    "
                  >
                    <img
                      v-if="item.wi_medaille > 0"
                      class="img-fluid img-macaron"
                      :src="
                        `${
                          $config.server_url
                        }/commons/1.0/files/yp_file_macaron_${$options.filters.formatvalue(
                          item.wi_medaille,
                          'items_medailles',
                          'lowercase',
                          'singular'
                        )}/${item.wi_year}`
                      "
                      alt="image macaron"
                    />
                  </div>
                  <img
                    v-else
                    class="img-fluid img-macaron"
                    src="/images/ok.png"
                    alt=""
                  />
                </div>
                <div class="ms-3">
                  <div class="fw-bold">
                    {{ item | formatWineName }}
                    <!-- <span v-if="item.wi_name2"> / {{ item.wi_name2 }}</span> -->
                  </div>
                  <div>
                    <!-- <span>{{ item.wi_medaille }}</span> -->
                    <span>{{ item.denomination.de_name }} - </span>
                    <span>{{ item.wi_couleur | formatWineColor }} - </span>
                    <span
                      >{{ item.wi_millesime | formatWineMillesime }} |
                    </span>
                    <span>
                      Nombre de vins déclarés :
                      <span
                        v-for="(contenant, index) in item.contenants"
                        :key="index"
                      >
                        {{ contenant.nombre }} x
                        {{ contenant.contenant / 100 }} cl
                        <span v-if="index < item.contenants.length - 1"
                          >,</span
                        ></span
                      ></span
                    >
                  </div>
                  <div>
                    <span>Fichiers : </span>
                    <span class="color-red" v-if="item.wi_revendication">
                      <span @click="downloadFile(1, item)" class="pointer"
                        >Déclaration de revendication
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            wi_revendication2 ||
                            wi_revendication3 ||
                            wi_revendication4 ||
                            wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication2">
                      <span @click="downloadFile(10, item)" class="pointer"
                        >Déclaration de revendication 2
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication2 &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            wi_revendication3 ||
                            wi_revendication4 ||
                            wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication3">
                      <span @click="downloadFile(11, item)" class="pointer"
                        >Déclaration de revendication 3
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication3 &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            wi_revendication4 ||
                            wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication4">
                      <span @click="downloadFile(12, item)" class="pointer"
                        >Déclaration de revendication 4
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication4 &&
                          (item.wi_analyse ||
                            item.wi_fichetech ||
                            wi_revendication5)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_revendication5">
                      <span @click="downloadFile(13, item)" class="pointer"
                        >Déclaration de revendication 5
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="
                        item.wi_revendication5 &&
                          (item.wi_analyse || item.wi_fichetech)
                      "
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_analyse">
                      <span @click="downloadFile(2, item)" class="pointer"
                        >Rapport d'analyse
                      </span></span
                    >
                    <span
                      class="color-red mx-1"
                      v-if="item.wi_fichetech && item.wi_analyse"
                      >|</span
                    >
                    <span class="color-red" v-if="item.wi_fichetech">
                      <span @click="downloadFile(3, item)" class="pointer"
                        >Fiche technique</span
                      ></span
                    >
                  </div>
                </div>
                <div class="d-md-none mt-2">
                  <div
                    v-if="
                      $dayjs().isSameOrAfter(
                        $store.state.userparticipation.yearpreferences
                          .yp_results_date,
                        'day'
                      ) && item.wi_medaille > 0
                    "
                  >
                    <button
                      @click="printcertificat(item)"
                      class="btn btn-primary mx-1 my-1 btn-sm"
                    >
                      Certificat
                    </button>
                    <button
                      @click="printAffiche(item)"
                      class="btn btn-primary mx-1 my-1 btn-sm"
                    >
                      Affiche
                    </button>
                  </div>
                  <div v-if="true" class="text-success">
                    Facture {{ item.invoice.in_num }}
                  </div>
                </div>
              </td>

              <td class="align-middle d-none d-md-table-cell">
                <div
                  v-if="
                    $dayjs().isSameOrAfter(
                      $store.state.userparticipation.yearpreferences
                        .yp_results_date,
                      'day'
                    ) && item.wi_medaille > 0
                  "
                >
                  <button
                    @click="printcertificat(item)"
                    class="btn btn-primary mx-1 my-1"
                  >
                    Certificat
                  </button>
                  <button
                    @click="printAffiche(item)"
                    class="btn btn-primary mx-1 my-1"
                  >
                    Affiche
                  </button>
                </div>
              </td>
              <td class="align-middle d-none d-md-table-cell">
                <div v-if="true" class="text-success">
                  Facture {{ item.invoice.in_num }}
                </div>
              </td>
            </template>
          </m-list-simple>
        </div>
      </div>
      <div class="mt-4">
        <div class="frame" v-if="row_pa.invoices.length">
          <h4 class="mb-1">
            {{
              row_pa.invoices.length > 1
                ? "Mes " + row_pa.invoices.length + " factures"
                : "Ma facture"
            }}
            <span></span>
          </h4>
          <p>
            Cliquez sur une ligne pour voir le détail des vins associés aux
            factures
          </p>
          <!-- grands écrans -->
          <m-list-simple
            tableClass="table-hover table-striped d-none d-md-table"
            style="overflow: visible;"
            ref="invoiceslist"
            :items="row_pa.invoices"
            item-value="in_id"
            :total="row_pa.invoices.length"
            @itemclick="showFacture"
          >
            <template v-slot:ths="{}">
              <th>Type</th>
              <th>Date</th>
              <th>Contact</th>
              <th>Factures</th>
              <th>Paiement</th>
              <th>Nb vins</th>
              <th class="text-end">Prix ht</th>
              <th>Payé</th>
              <th></th>
            </template>
            <template v-slot:tds="{ item }">
              <td class="">{{ item.in_type }}</td>
              <td class="">{{ item.createdAt | formatDate }}</td>
              <td class="">{{ item.in_type }}</td>
              <td>{{ item.in_num }}</td>
              <td>{{ item.in_typepaiement }}</td>
              <td>{{ item.wines.length }}</td>
              <td class="text-end">
                <span v-if="item.in_type == 'AVOIR'">-</span
                >{{ item.in_price_ht | formatPriceDivisePar100 }}
              </td>
              <td v-if="item.in_payed">
                {{ item.in_payed_date | formatDate }}
              </td>
              <td v-else>
                <button
                  v-if="item.in_year === $store.state.currentyear"
                  class="btn btn-dark btn-sm"
                  @click.stop="payer(item.in_id)"
                >
                  Payer
                </button>
              </td>
              <td>
                <div class="d-flex justify-content-end">
                  <div
                    @click.stop="sendFacture(item)"
                    class="me-3 pointer"
                    v-tooltip
                    data-bs-placement="left"
                    title="Recevoir la facture par email"
                  >
                    <icon color="#3fb1dc" name="envelope"></icon>
                  </div>
                  <div
                    @click.stop="print(item)"
                    class="me-3 pointer"
                    v-tooltip
                    data-bs-placement="left"
                    title="Télécharger la facture au format PDF'"
                  >
                    <icon name="print"></icon>
                  </div>
                </div>
              </td>
            </template>
          </m-list-simple>
          <!-- petits écrans -->
          <m-list-simple
            tableClass="table-hover table-striped d-md-none"
            style="overflow: visible;"
            ref="invoiceslist"
            :items="row_pa.invoices"
            item-value="in_id"
            :total="row_pa.invoices.length"
            @itemclick="showFacture"
          >
            <template v-slot:ths="{}"></template>
            <template v-slot:tds="{ item }">
              <td class="">
                <div>
                  {{ item.in_type }} n°{{ item.in_num }} |
                  {{ item.in_typepaiement }}
                </div>
                <div>Nombre vins : {{ item.wines.length }}</div>
                <div>
                  Total HT : <span v-if="item.in_type == 'AVOIR'">-</span
                  >{{ item.in_price_ht | formatPriceDivisePar100 }}
                </div>
                <div v-if="item.in_payed">
                  Payé le : {{ item.in_payed_date | formatDate }}
                </div>
                <div v-else class="d-flex align-items-center">
                  <button
                    v-if="item.in_year === $store.state.currentyear"
                    class="btn btn-dark btn-sm ms-2"
                    @click.stop="payer(item.in_id)"
                  >
                    Payer
                  </button>
                </div>
                <div class="d-flex">
                  <button
                    @click.stop="sendFacture(item)"
                    class="btn btn-primary btn-sm mx-1 my-1"
                  >
                    Email
                  </button>
                  <button
                    @click.stop="print(item)"
                    class="btn btn-primary btn-sm  mx-1 my-1"
                  >
                    Impression
                  </button>
                </div>
              </td>
            </template>
          </m-list-simple>
          <!--  -->
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="frame" v-if="row_pa.orders.length">
        <h4 class="mb-1">
          {{
            row_pa.orders.length > 1
              ? "Mes " + row_pa.orders.length + " commandes de macarons"
              : "Ma commande de macarons"
          }}
          <span></span>
        </h4>
        <p>
          Cliquez sur une ligne pour voir le détail de la commande
        </p>
        <!-- pour grand écran -->
        <m-list-simple
          tableClass="table-hover table-striped d-none d-md-table"
          style="overflow: visible;"
          ref="invoiceslist"
          :items="row_pa.orders"
          item-value="in_id"
          :total="row_pa.orders.length"
          @itemclick="showOrder"
        >
          <template v-slot:ths="{}">
            <th>Date</th>
            <th>Société</th>
            <th>Contact</th>
            <!-- <th>Or</th>
            <th>Argent</th>
            <th>Excellence</th> -->
            <th>Commande</th>
            <th>Prix HT</th>
            <th>Payé (État)</th>
            <th>Envoyé</th>
            <th></th>
          </template>
          <template v-slot:tds="{ item }">
            <td>{{ item.or_date | formatDate }}</td>
            <td class="">{{ item.or_society_fac }}</td>
            <td class="">
              {{
                $options.filters.formatContactNameSimple(
                  item,
                  "or_",
                  false,
                  "_liv"
                )
              }}
            </td>
            <td>{{ item.commande }}</td>
            <!-- <td>{{ item.or_or }}</td>
            <td>{{ item.or_argent }}</td>
            <td>{{ item.or_excellence }}</td> -->
            <td>{{ item.or_price_ht | formatPriceDivisePar100 }}</td>
            <td>
              <span
                v-html="$options.filters.formatYesNoColored(item.or_payed)"
              ></span
              >&nbsp;
              <span v-if="item.or_state == 'CREATING'">(En création)</span>
              <span v-if="item.or_state == 'VALIDATED'">(Validé)</span>
              <span v-if="item.or_state == 'RECEIVED'">(En traitement)</span>
              <span v-if="item.or_state == 'PAYED'">(Payé)</span>
              <span v-if="item.or_state == 'SENDED'">(Envoyé)</span>
            </td>
            <td>
              {{
                item.or_state === "SENDED"
                  ? $options.filters.formatDate(item.or_datesended)
                  : "Non"
              }}
            </td>
            <td>
              <div class="d-flex justify-content-end">
                <!-- <div
                  @click.stop="sendOrder(item)"
                  class="me-3 pointer"
                  v-tooltip
                  data-bs-placement="left"
                  title="Recevoir l'email d'état de la commande macarons'"
                >
                  <icon color="#3fb1dc" name="envelope"></icon>
                </div> -->
                <div
                  @click.stop="showOrder(item)"
                  class="me-3 pointer"
                  v-if="item.or_state === 'RECEIVED'"
                >
                  <icon name="euro-sign" color="#3fb1dc"></icon>
                </div>
                <div @click.stop="printOrder(item)" class="me-3 pointer">
                  <icon name="print"></icon>
                </div>
              </div>
            </td>
          </template>
        </m-list-simple>
        <!-- pour petit écran -->
        <m-list-simple
          tableClass="table-hover table-striped d-md-none"
          style="overflow: visible;"
          ref="invoiceslist"
          :items="row_pa.orders"
          item-value="in_id"
          :total="row_pa.orders.length"
          @itemclick="showOrder"
        >
          <template v-slot:ths="{}"> </template>
          <template v-slot:tds="{ item }">
            <td class="">
              <div>Date : {{ item.or_date | formatDate }}</div>
              <div class="d-flex">
                <div>
                  {{ item.or_society_fac }} |
                  {{
                    $options.filters.formatContactNameSimple(
                      item,
                      "or_",
                      false,
                      "_liv"
                    )
                  }}
                </div>
              </div>
              <div>{{ item.commande }}</div>
              <!-- <div>Or : {{ item.or_or }}</div>
              <div>Argent : {{ item.or_argent }}</div>
              <div>Excellence : {{ item.or_excellence }}</div> -->
              <div>
                Total HT : {{ item.or_price_ht | formatPriceDivisePar100 }}
              </div>
              <div v-if="item.or_date">
                Payée le : {{ item.or_date | formatDate }}
              </div>
              <div
                v-else
                v-html="'<span class=\'text-danger\'>NON PAYÉE</span>'"
              ></div>
              <div
                v-if="item.or_state === 'SENDED'"
                v-html="'<span class=\'text-success\'>ENVOYÉE</span>'"
              ></div>
              <div
                v-else
                v-html="'<span class=\'text-danger\'>NON ENVOYÉE</span>'"
              ></div>
              <button
                @click.stop="sendOrder(item)"
                class="btn btn-primary btn-sm pointer"
              >
                Envoyer email
              </button>
            </td>
          </template>
        </m-list-simple>
      </div>
    </div>
    <m-confirm-dialog
      v-model="confirmdeleteWine"
      text="Voulez-vous supprimer ce vin ? "
      title="Confirmation"
      @canceled="confirmdeleteWine = false"
      @confirmed="deleteWine()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="confirmCreateNewParticipation"
      :text="confirmNewParticipationTxt"
      title="Confirmation"
      btnOkTxt="Oui"
      btnCancelTxt="Non"
      @canceled="iWantCreateNextParticipation(false)"
      @confirmed="iWantCreateNextParticipation(true)"
    ></m-confirm-dialog>
    <m-message-dialog
      v-model="messageCB"
      :title="titleMessageCb"
      :text="textMessageCb"
      :redirect="redirectMessageCb"
      :color="colorMessageCb"
    ></m-message-dialog>
    <wine-win-edit
      v-model="wineWinEdit"
      :wi_id="wineToEdit.wi_id"
      :row_pa="row_pa"
      @WinEditActions="WineWinEditActions"
    ></wine-win-edit>
    <candidat-jure-win-edit
      v-model="candidatjureWinEdit"
      :pa_id="row_pa.pa_id"
      @WinEditAction="candidatJureWinEditAction"
    ></candidat-jure-win-edit>
    <invoice-avoir-win-edit
      v-model="invoiceWinRead"
      :in_id="invoiceToShow.in_id"
      @close="invoiceWinRead = false"
    ></invoice-avoir-win-edit>
    <payer-win-edit
      v-model="payerWinEdit"
      :pa_id="row_pa.pa_id"
      :in_id="this.payInvoice"
      @WinEditActions="WinPayerActions"
    ></payer-win-edit>
    <order-payer-win-edit
      v-model="showOrderPayerWinEdit"
      :pa_id="row_pa.pa_id"
      :in_id="this.payInvoice"
      @OrderPayerWinEditActions="OrderPayerWinEditActions"
    ></order-payer-win-edit>
    <order-new-1
      v-model="orderWinEdit1"
      :row_pa="row_pa"
      :or_id="orderCreating.or_id"
      @WinEditActions="WinOrderNew1Actions"
    ></order-new-1>
    <order-new-2
      v-model="orderWinEdit2"
      :or_id="orderCreating.or_id"
      :row_pa="row_pa"
      @returnStep1="returnStep1"
      @WinEditActions="WinOrderNew2Actions"
    ></order-new-2>
    <contact-win :row_pa="row_pa" v-model="contactWin"></contact-win>
  </section>
</template>

<script>
export default {
  name: "home",
  components: {},
  data() {
    return {
      payInvoice: 0,
      year: this.$store.state.year,
      row_pa: {
        winesValide: [],
        winesCreate: [],
        orders: [],
        invoices: [],
        login: {}
      },
      candidatjureWinEdit: false,
      wineWinEdit: false,
      wineToEdit: {},
      disabledPayer: true,
      invoiceWinRead: false,
      invoiceToShow: { wines: [] },
      orderWinEdit1: false,
      orderWinEdit2: false,
      orderCreating: {},
      payerWinEdit: false,
      confirmdeleteWine: false,
      wineToDelete: {},
      canPrintColisage: true,
      titleMessageCb: "",
      textMessageCb: "",
      redirectMessageCb: "",
      colorMessageCb: "",
      messageCB: false,
      confirmCreateNewParticipation: false,
      contactWin: false,
      showOrderPayerWinEdit: false
    };
  },
  computed: {
    confirmNewParticipationTxt() {
      return (
        "Souhaitez-vous vous inscrire au concours " +
        this.$store.state.year +
        " ?"
      );
    }
  },
  async mounted() {
    await this.loadParticipation();
    // console.log("this.$route", this.$route.path);
    if (this.$route.path === "/home/cb/ok") {
      // this.titleMessageCb = "Confirmation de Paiement";
      this.textMessageCb = this.$store.state.userparticipation.yearpreferences.yp_infos_payment_cb_ok;
      this.redirectMessageCb = "home";
      this.colorMessageCb = "green";
      this.messageCB = true;
    }
    if (this.$route.path === "/home/cb/ok2") {
      this.titleMessageCb = "Paiement réussi";
      this.textMessageCb =
        "Félicitations, vous venez de procéder au paiement de votre commande. Nous vous tiendrons informés par email lorsque les macarons seront imprimés et envoyés.";
      this.redirectMessageCb = "home";
      this.colorMessageCb = "green";
      this.messageCB = true;
    }
    if (this.$route.path === "/home/cb/ko") {
      // this.titleMessageCb = "Erreur de Paiement";
      this.textMessageCb = this.$store.state.userparticipation.yearpreferences.yp_infos_payment_cb_ko;
      this.redirectMessageCb = "home";
      this.colorMessageCb = "red";
      this.messageCB = true;
    }
    if (this.$route.name == "OrderStep2")
      this.showOrder({ or_id: this.$route.params.id * 1 });
    if (this.$route.name == "OrderStep1") {
      this.orderCreating = { or_id: this.$route.params.id * 1 };
      this.$router
        .push("/orders/" + this.orderCreating.or_id + "/step1")
        .catch(err => {});
      this.orderWinEdit1 = true;
    }
  },
  watch: {
    year: async function(val) {
      await this.$store.dispatch("set_year", val);
      this.loadParticipation();
    }
  },
  methods: {
    printOrder(item) {
      window.open(
        `${this.$config.server_url}/candidats/1.0/orders/print/${item.or_id}?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    payOrder(item) {
      this.showOrderPayerWinEdit = true;
    },
    showWinContact() {
      this.contactWin = true;
    },
    getTitleVinsIscrits() {
      if (!this.row_pa.winesValide.length) return "Mes vins inscrits";
      let title =
        this.row_pa.winesValide.length > 1
          ? "Mes " + this.row_pa.winesValide.length + " vins"
          : "Mon vin";
      if (
        this.$dayjs().isBetween(
          this.$store.state.userparticipation.yearpreferences
            .yp_start_inscription_date,
          this.$store.state.userparticipation.yearpreferences
            .yp_end_inscription_date_candidate,
          "day",
          "[]"
        )
      )
        title += " déjà";
      title += this.row_pa.winesValide.length > 1 ? " inscrits" : " inscrit";
      return title;
    },
    printColisage() {
      window.open(
        `${this.$config.server_url}/candidats/1.0/participations/${this.row_pa.pa_id}/etiquette?token=${this.$store.state.accesstoken}&origin=${this.$config.backoffice_url}`,
        "_blank"
      );
    },
    async loadParticipation() {
      let response;
      this.cancelAxiosLoadJures && this.cancelAxiosLoadJures();
      response = await this.$axios.get(
        this.$config.server_url +
          "/candidats/1.0/participations/" +
          this.$store.state.year +
          "/fromyear",
        {
          cancelToken: new this.$axios.CancelToken(c => {
            this.cancelAxiosLoadJures = c;
          })
        }
      );
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      if (!response || !response.data) return;
      let row_pa = response.data.data;
      for (let iPa = 0; iPa < row_pa.orders.length; iPa++) {
        const o = row_pa.orders[iPa];
        o.commande = [];
        if (o.or_or) o.commande.push("Or : " + o.or_or);
        if (o.or_argent) o.commande.push("Argent : " + o.or_argent);
        if (o.or_excellence) o.commande.push("Excellence : " + o.or_excellence);
        o.commande = o.commande.join(" | ");
      }

      this.row_pa = row_pa;

      //console.log("response.data.participation", response.data.participation);
      this.$store.dispatch(
        "set_userparticipation",
        response.data.participation
      );
      // contenances (nombre de boutilles totale)
      for (let iwi = 0; iwi < this.row_pa.winesValide.length; iwi++) {
        const wi = this.row_pa.winesValide[iwi];
        wi.contenants = [];
        if (wi.wi_contenances) {
          let tabC = wi.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              wi.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      for (let iwi = 0; iwi < this.row_pa.winesCreate.length; iwi++) {
        const wi = this.row_pa.winesCreate[iwi];
        wi.contenants = [];
        if (wi.wi_contenances) {
          let tabC = wi.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              wi.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      this.calculDisabledPayer();
      if (
        !this.row_pa.pa_candidat &&
        !this.row_pa.pa_jure &&
        this.$dayjs().isBetween(
          this.$store.state.userparticipation.yearpreferences
            .yp_start_inscription_date,
          this.$store.state.userparticipation.yearpreferences
            .yp_end_inscription_date_candidate,
          "day",
          "[]"
        )
      ) {
        //this.editCandidat();
        this.confirmCreateNewParticipation = true;
      }

      // disable ou non le bouton "imprimer colisage"
      if (this.row_pa.winesValide.length) {
        /* let notPayed = this.row_pa.winesValide.filter(wine => {
          return !wine.wi_payed;
        }); */
        //if (notPayed.length === this.row_pa.winesValide.length)
        this.canPrintColisage = true;
      } else {
        this.canPrintColisage = false;
      }
    },
    async iWantCreateNextParticipation(iWant) {
      if (iWant) {
        this.editCandidat();
      } else {
        let years = this.$store.state.items_years.filter(item => {
          return item.pa_id && item.pa_id > 0;
        });
        //console.log("items_years, years", this.$store.state.items_years, years);
        if (years.length) this.year = years[0].value;
        else this.year = this.$store.state.items_years[0].value;
        /* if (this.$store.state.preferences.participationsYears.length)
          this.year = this.$store.state.preferences.participationsYears[0]; */
      }
      this.confirmCreateNewParticipation = false;
    },
    editCandidat() {
      this.candidatjureWinEdit = true;
      this.$router.push("/edit/participation").catch(err => {});
    },
    candidatJureWinEditAction(what) {
      if (what.action === "saved") this.loadParticipation();
      this.$router.push("/home").catch(err => {});
    },
    /// les vins
    calculDisabledPayer() {
      let winePasOk = false;
      for (let iwi = 0; iwi < this.row_pa.winesCreate.length; iwi++) {
        const wi = this.row_pa.winesCreate[iwi];
        if (!wi.wi_revendication || !wi.wi_analyse) {
          winePasOk = true;
          break;
        }
      }
      this.disabledPayer = winePasOk;
    },
    addWine() {
      this.wineToEdit = { wi_id: -1 };
      this.wineWinEdit = true;
      this.$router.push("/edit/wine/-1").catch(err => {});
    },
    editWine(item) {
      this.wineToEdit = item;
      this.wineWinEdit = true;
      this.$router.push("/edit/wine/" + item.wi_id).catch(err => {});
    },
    confirmDeleteWine(item) {
      this.wineToDelete = item;
      this.confirmdeleteWine = true;
    },
    async deleteWine() {
      this.confirmdeleteWine = false;
      await this.$axios.delete(
        this.$config.server_url +
          "/candidats/1.0/wines/" +
          this.wineToDelete.wi_id
      );
      this.loadParticipation();
    },
    WineWinEditActions(what) {
      // console.log("what", what);
      if (what.action === "saved") this.loadParticipation();
      this.$router.push("/home").catch(err => {});
    },
    downloadFile(num, item) {
      window.open(
        `${this.$config.server_url}/candidats/1.0/wines/${item.wi_id}/files/${num}/${item.wi_year}?token=${this.$store.state.accesstoken}&origin=${this.$config.candidats_url}`,
        "_blank"
      );
    },
    payer(in_id) {
      if (in_id) this.payInvoice = in_id;
      else this.payInvoice = 0;
      this.payerWinEdit = true;
      this.$router.push("/valid").catch(err => {});
    },
    WinPayerActions(what) {
      this.payerWinEdit = false;
      this.$router.push("/home").catch(err => {});
      if (what.action === "saved") {
        this.loadParticipation();
      }
    },
    OrderPayerWinEditActions(what) {
      // console.log("what", what);
      this.showOrderPayerWinEdit = false;
    },
    /// les factures
    showFacture(item) {
      this.invoiceWinRead = true;
      this.invoiceToShow = item;
    },
    async print(item) {
      let data = {
        in_id: item.in_id,
        eventLog: "printInvoice"
      };
      data.comment = item.in_type === "AVOIR" ? "avoir" : "facture";
      await this.$axios.post(
        this.$config.server_url + "/candidats/1.0/addlog/",
        data
      );
      window.open(
        `${this.$config.server_url}/candidats/1.0/invoices/${item.in_id}/print?token=${this.$store.state.accesstoken}&origin=${this.$config.candidats_url}`,
        "_blank"
      );
    },
    async sendFacture(item) {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/candidats/1.0/invoices/" +
          item.in_id +
          "/send"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Envoi OK ",
          text: "La facture a bien été envoyée",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Erreur",
          text: response.data.data.error,
          color: "red"
        });
      }
    },
    /* printEtiquette() {
      window.open(
        `${this.$config.server_url}/candidats/1.0/invoices/${this.row_in.in_id}/etiquette?token=${this.$store.state.accesstoken}&origin=${this.$config.candidats_url}`,
        "_blank"
      );
    }, */
    printcertificat(item) {
      window.open(
        `${this.$config.server_url}/candidats/1.0/wines/${item.wi_id}/certificat?token=${this.$store.state.accesstoken}&origin=${this.$config.candidats_url}`,
        "_blank"
      );
    },
    printAffiche(item) {
      window.open(
        `${this.$config.server_url}/candidats/1.0/wines/${item.wi_id}/affiche?token=${this.$store.state.accesstoken}&origin=${this.$config.candidats_url}`,
        "_blank"
      );
    },
    /// commande macaron
    async sendOrder(item) {
      let response = await this.$axios.post(
        this.$config.server_url +
          "/candidats/1.0/orders/" +
          item.or_id +
          "/sendemail"
      );
      if (response.data.data.success) {
        this.$store.dispatch("showToast", {
          title: "Commande de macaron",
          text: "Email bien envoyé",
          color: "green"
        });
      } else {
        this.$store.dispatch("showToast", {
          title: "Commande de macaron",
          text: response.data.data.error,
          color: "red"
        });
      }
    },
    showOrder(item) {
      this.orderCreating = item;
      this.$router
        .push("/orders/" + this.orderCreating.or_id + "/step2")
        .catch(err => {});
      this.orderWinEdit2 = true;
    },
    newOrder() {
      this.orderCreating = { or_id: -1 };
      this.$router.push("/orders/-1/step1").catch(err => {});
      this.orderWinEdit1 = true;
    },
    WinOrderNew1Actions(data) {
      if (data.action === "canceled") {
        this.$router.push("/home").catch(err => {});
        this.orderCreating = {};
      }
      if (data.action === "saved") {
        this.orderCreating = data.data;
        this.orderWinEdit2 = true;
        this.$router
          .push("/orders/" + this.orderCreating.or_id + "/step2")
          .catch(err => {});
      }
    },
    returnStep1(row_or) {
      this.orderCreating = row_or;
      this.orderWinEdit1 = true;
      this.$router
        .push("/orders/" + this.orderCreating.or_id + "/step1")
        .catch(err => {});
    },
    WinOrderNew2Actions(data) {
      // console.log("🚀 ~ WinOrderNew2Actions ~ data:", data);
      if (data.action === "canceled") {
        return;
      }
      this.titleMessageCb = "Validation de la commande";
      this.textMessageCb =
        "Nous vous remercions pour votre commande de macarons. Notre équipe va valider cette commande et calculer les frais de port. Sitôt fait, vous recevrez alors un email vous proposant de payer par Carte bancaire cette commande.";
      this.redirectMessageCb = "home";
      this.colorMessageCb = "green";
      this.messageCB = true;

      this.$router.push("/home").catch(err => {});
      this.orderCreating = {};
      if (data.action === "saved") this.loadParticipation();
    }
  }
};
</script>

<style lang="scss" scoped>
.frame-wines-height {
  min-height: 300px;
}

.img-macaron {
  width: 50px;
  max-width: 50px;
}
</style>
